.app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.chat-window {
  height: 80vh;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  flex: 1;
  min-height: 0;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.messages {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  scroll-behavior: smooth;
  overflow-x: hidden;
  width: 100%;
}

.messages > div:last-child {
  float: left;
  clear: both;
}

.message {
  max-width: 85%;
  padding: 12px 18px;
  border-radius: 15px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.message.user {
  background-color: #ffffff;
  color: #000000;
  align-self: flex-end;
}

.message.bot {
  background-color: #f1f1f1;
  color: #333;
  align-self: flex-start;
}

.input-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  display: flex;
  gap: 8px;
  padding: 15px;
  background-color: #f8f9fa;
  border-top: 1px solid #eee;
}

.file-input-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.file-label {
  padding: 8px 12px;
  background: #f0f0f0;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  white-space: nowrap;
}

.file-label:hover {
  background: #e0e0e0;
}

.file-label.disabled {
  background: #ccc;
  cursor: not-allowed;
}

.file-name {
  font-size: 0.9em;
  color: #666;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

input[type="text"] {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

input[type="text"]:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

button {
  padding: 10px 20px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

button:hover:not(:disabled) {
  background: #0056b3;
}

button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

/* Login styles */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.login-form {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.login-form h2 {
  margin-bottom: 1.5rem;
  text-align: center;
  color: #333;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #555;
}

.form-group input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.login-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.login-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: #dc3545;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 0.9rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.logout-button {
  padding: 0.5rem 1rem;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.logout-button:hover {
  background-color: #c82333;
}

.files-preview {
  padding: 8px 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.file-preview {
  display: flex;
  align-items: center;
  background: #e9ecef;
  padding: 4px 8px;
  border-radius: 4px;
  gap: 8px;
  max-width: 200px;
  min-width: 100px;
}

.file-preview .file-name {
  font-size: 0.85rem;
  color: #495057;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.remove-file {
  background: none;
  border: none;
  color: #dc3545;
  padding: 0 4px;
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 1;
}

.remove-file:hover {
  background: #dc3545;
  color: white;
  border-radius: 50%;
}

/* Add loading indicator styles */
.typing-indicator {
  display: flex;
  gap: 4px;
  padding: 4px 8px;
}

.typing-indicator span {
  width: 8px;
  height: 8px;
  background: #007bff;
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out;
}

.typing-indicator span:nth-child(1) { animation-delay: -0.32s; }
.typing-indicator span:nth-child(2) { animation-delay: -0.16s; }

@keyframes bounce {
  0%, 80%, 100% { transform: scale(0); }
  40% { transform: scale(1); }
}

/* Add these to your existing CSS */
.message pre {
  background-color: #f4f4f4;
  padding: 1rem;
  border-radius: 4px;
  overflow-x: auto;
  margin: 0.5rem 0;
}

.message code {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0.2rem 0.4rem;
  border-radius: 3px;
  font-family: monospace;
}

.message.user pre,
.message.user code {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
}

.message p {
  margin: 0;
  line-height: 1.5;
}

.message ul, 
.message ol {
  margin: 0.5rem 0;
  padding-left: 1.5rem;
}

.message h1, 
.message h2, 
.message h3, 
.message h4, 
.message h5, 
.message h6 {
  margin: 0.5rem 0;
  line-height: 1.2;
}

.message a {
  color: #0066cc;
  text-decoration: underline;
}

.message.user a {
  color: #ffffff;
}

.message blockquote {
  border-left: 3px solid #ccc;
  margin: 0.5rem 0;
  padding-left: 1rem;
  color: #666;
}

.message.user blockquote {
  border-left-color: rgba(255, 255, 255, 0.4);
  color: rgba(255, 255, 255, 0.8);
}

/* Message actions styling */
.message-content {
  width: 100%;
  overflow-wrap: break-word;
}

.message-footer {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
}

.copy-button {
  background: none;
  border: 1px solid #ddd;
  padding: 4px 12px;
  border-radius: 4px;
  cursor: pointer;
  color: #666;
  font-size: 14px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 4px;
}

.copy-button:hover {
  background-color: #f5f5f5;
  color: #333;
}

.message.user .copy-button {
  border-color: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.8);
}

.message.user .copy-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}
